<template>
    <div style="background: red;width: 100%;height: 100%;">
        <!-- <iframe :src="src" frameborder="0" style="width: 100%;height: 100%;"></iframe> -->
        <!-- <el-button @click="getVtoken" style="position: fixed;top: 50%;left: 50%;">实名认证</el-button> -->
    </div>
</template>
<script>
export default {
    data() {
        return {
            phone: "",
            Atoken: "",
            src: ""
        };
    },
    props: {},
    components: {},
    methods: {
        // async getAtoken() {
        //     await this.$http.Get(`/auth/VeriRecord/token?phone=17630858775`).then(res => {
        //         if (res.data.code == 200) {
        //             this.Atoken = JSON.parse(res.data.data).access_token
        //             this.getVtoken()
        //         }
        //     })
        // },
        async getVtoken() {
            if (!this.Atoken) {
                this.$message.error('无Token');
                return
            }
            await this.$http.post('https://aip.baidubce.com/rpc/2.0/brain/solution/faceprint/verifyToken/generate?access_token=' + this.Atoken, {
                plan_id: '16384'
            }).then(res => {
                if (res.statusCode == 200) {
                    this.Vtoken = res.data.result.verify_token
                    let url = `token=${this.Vtoken}&successUrl=https://hzq.yoojet.com/#/pass/facePassLoading&failedUrl=https://hzq.yoojet.com/#/pass/facePassError`
                    window.open('https://h5-faceverify.baidu.com/face/print/?' + url, '_self')
                }
            })
        }
    },
    mounted() { },
    created() {
        this.phone = this.$route.query.phone
        this.Vtoken = this.$route.query.token
        this.$message.success(this.Vtoken);
        this.src = `https://h5-faceverify.baidu.com/face/print/?token=${this.Vtoken}&successUrl=https://hzq.yoojet.com/#/pass/facePassLoading&failedUrl=https://hzq.yoojet.com/#/pass/facePassError`
        window.open(this.src, '_self')
        // this.getAtoken()
        // alert(this.Atoken)
    },
    computed: {},
    watch: {},
};
</script>
<style lang='less' scoped></style>